var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"z-index":"10"},style:({
    'align-self': _vm.isChatOpen ? 'start' : null,
    'margin-top': _vm.isChatOpen ? '-24px' : null,
    'margin-left': _vm.isChatOpen ? '12px' : null,
    'margin-right': _vm.isChatOpen ? '-24px' : null,
  })},[(_vm.isChatOpen)?_c('v-sheet',{staticClass:"pa-0 transparent",attrs:{"light":""}},[_c('v-card',{staticStyle:{"border":"none"},attrs:{"elevation":"0","no-nody":""}},[_c('v-card-text',{staticClass:"pa-0",staticStyle:{"border":"none"}},[_c('v-container',{staticStyle:{"width":"615px"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"background",staticStyle:{"height":"80px"},attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"d-flex"},[_c('h4',{staticClass:"white--text",staticStyle:{"font-weight":"700 !important"}},[_vm._v(" CHAT ")]),_c('v-icon',{attrs:{"color":"primary","right":""}},[_vm._v("fas fa-comments")])],1)]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-autocomplete',{attrs:{"dark":"","solo":"","dense":"","color":"darker-grey","label":"Search Colleague","placeholder":"Search Colleague","items":_vm.colleagueOptions,"clearable":"","hide-details":""},on:{"change":function($event){return _vm.onSearch($event)}}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":_vm.toggleChat}},[_c('v-icon',[_vm._v(_vm._s(_vm.isChatOpen ? "fas fa-times-circle" : "fas fa-comments"))])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 px-0",attrs:{"cols":"4"}},[_c('v-list',{staticClass:"pa-0",staticStyle:{"overflow-y":"auto","max-height":"550px"}},_vm._l((_vm.chats),function(chat){return _c('v-list-item',{key:chat.id,staticClass:"d-flex justify-space-between mb-1",class:[
                    _vm.activeChatId === chat.id ? 'lighter-grey' : 'light-grey' ],on:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('SET_ACTIVE_CHAT_ID', chat.id)}}},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"75%"}},[_vm._v(_vm._s(chat.name))]),(!chat.read && chat !== _vm.active)?_c('div',{staticClass:"\n                      rounded-circle\n                      red\n                      white--text\n                      d-flex\n                      align-center\n                      justify-center\n                    ",staticStyle:{"height":"15px","width":"14px","font-size":"11px"}},[_vm._v(" ! ")]):_vm._e()])}),1)],1),_c('v-col',{staticClass:"pb-0 pt-5 lighter-grey",attrs:{"cols":"8"}},[(!_vm.active)?_c('div',{staticClass:"pa-8"},[_c('p',[_vm._v("Select a conversation to start chatting")])]):[_c('div',{staticClass:"d-flex justify-space-between px-4",staticStyle:{"width":"100%"}},[_c('h3',{staticClass:"black--text"},[_vm._v(" "+_vm._s(_vm.active.name)+" ")]),_c('v-icon',{attrs:{"color":"black"}},[_vm._v("fas fa-user")])],1),_c('lh-chat',{ref:"chat",staticClass:"mt-8",attrs:{"max-height":"550px","use-custom-backend":"","messages":_vm.messages,"is-private-chat":""},on:{"submit":function($event){return _vm.onSubmit($event)}}})]],2)],1)],1)],1)],1)],1):[_vm._t("activator",null,null,{
        on: {
          click: function () { return _vm.toggleChat(); },
        },
        unreadMessages: _vm.unreadMessages,
      })]],2)}
var staticRenderFns = []

export { render, staticRenderFns }