<template>
  <div
    :style="{
      'align-self': isChatOpen ? 'start' : null,
      'margin-top': isChatOpen ? '-24px' : null,
      'margin-left': isChatOpen ? '12px' : null,
      'margin-right': isChatOpen ? '-24px' : null,
    }"
    style="z-index: 10"
  >
    <v-sheet v-if="isChatOpen" class="pa-0 transparent" light>
      <v-card elevation="0" no-nody style="border: none">
        <v-card-text class="pa-0" style="border: none">
          <v-container fluid style="width: 615px">
            <v-row class="background" align="center" style="height: 80px">
              <v-col cols="2"
                ><div class="d-flex">
                  <h4 class="white--text" style="font-weight: 700 !important">
                    CHAT
                  </h4>
                  <v-icon color="primary" right>fas fa-comments</v-icon>
                </div></v-col
              >
              <v-col cols="8">
                <!-- Filter -->
                <v-autocomplete
                  dark
                  solo
                  dense
                  color="darker-grey"
                  label="Search Colleague"
                  placeholder="Search Colleague"
                  :items="colleagueOptions"
                  clearable
                  @change="onSearch($event)"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-btn color="primary" icon @click="toggleChat"
                  ><v-icon>{{
                    isChatOpen ? "fas fa-times-circle" : "fas fa-comments"
                  }}</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <!-- Users -->
              <v-col cols="4" class="pt-0 px-0">
                <v-list
                  style="overflow-y: auto; max-height: 550px"
                  class="pa-0"
                >
                  <v-list-item
                    v-for="chat in chats"
                    :key="chat.id"
                    @click.stop="$store.commit('SET_ACTIVE_CHAT_ID', chat.id)"
                    :class="[
                      activeChatId === chat.id ? 'lighter-grey' : 'light-grey',
                    ]"
                    class="d-flex justify-space-between mb-1"
                  >
                    <span class="text-truncate" style="max-width: 75%">{{
                      chat.name
                    }}</span>

                    <div
                      style="height: 15px; width: 14px; font-size: 11px"
                      class="
                        rounded-circle
                        red
                        white--text
                        d-flex
                        align-center
                        justify-center
                      "
                      v-if="!chat.read && chat !== active"
                    >
                      !
                    </div>
                  </v-list-item>
                </v-list>
              </v-col>
              <!-- Chat -->
              <v-col cols="8" class="pb-0 pt-5 lighter-grey">
                <div v-if="!active" class="pa-8">
                  <p>Select a conversation to start chatting</p>
                </div>
                <template v-else>
                  <div
                    class="d-flex justify-space-between px-4"
                    style="width: 100%"
                  >
                    <h3 class="black--text">
                      {{ active.name }}
                    </h3>

                    <v-icon color="black">fas fa-user</v-icon>
                  </div>
                  <lh-chat
                    class="mt-8"
                    max-height="550px"
                    use-custom-backend
                    :messages="messages"
                    is-private-chat
                    @submit="onSubmit($event)"
                    ref="chat"
                  ></lh-chat>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-sheet>

    <template v-else>
      <!-- Activator -->
      <slot
        name="activator"
        v-bind="{
          on: {
            click: () => toggleChat(),
          },
          unreadMessages,
        }"
      ></slot>
    </template>
  </div>
</template>

<script>
import "emoji-mart-vue-fast/css/emoji-mart.css";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      filter: null,
      sub: null,
    };
  },

  async mounted() {
    await this.updateChats();

    if (this.activeChatId && !this.active?.messages) {
      // Reload active chat
      this.updateActiveChat();
    }

    this.createListener();

    console.log("Mounted chat");
  },

  beforeDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  },

  computed: {
    ...mapState(["colleagues", "chats", "isChatOpen", "activeChatId"]),
    ...mapGetters(["unreadMessages", "me", "isAuthenticated"]),
    active() {
      if (!this.activeChatId) return null;

      return this.chats.filter((chat) => chat.id === this.activeChatId)[0];
    },
    messages() {
      if (!this.active) return [];

      return this.active?.chat || [];
    },
    colleagueOptions() {
      return this.colleagues.map((colleague) => {
        return {
          text: colleague.name,
          value: colleague.id,
        };
      });
    },
  },

  /*watch: {
    unreadMessages() {
      console.log("Unreadmessages has changed. Value is:", this.active);

      if (!this.active.read) {
        console.log("Reload active chat");
        this.updateChat({ chatId: newValue.id });

        this.$refs.chat.scrollToBottom();
      }
    },
  },*/

  methods: {
    ...mapActions(["updateChats", "updateChat", "sendChat", "toggleChat"]),
    async onSubmit(data) {
      console.log("Sent chat:", data);

      await this.sendChat({ chatId: this.activeChatId, message: data });

      this.$refs.chat.scrollToBottom();
    },

    onSearch(chatId) {
      this.$store.commit("SET_ACTIVE_CHAT_ID", chatId);
    },

    async updateActiveChat() {
      if (!this.activeChatId) return;

      console.log("Getting new chat:", this.activeChatId);
      await this.updateChat({ chatId: this.activeChatId });

      if (this.$refs.chat) {
        this.$refs.chat.scrollToBottom();
      }
    },

    createListener() {
      if (!this.me) {
        console.log("User is not logged in... Skipping chat listener setup");
        return;
      }

      this.sub = this.$livehouse.eventcdn.broadcast.channels[
        "user"
      ].messages$.subscribe(async (message) => {
        if (message.action === "new_message") {
          if (this.active && this.active.id === message.sender_id) {
            // Reload chat
            await this.updateChat({ chatId: this.active.id });

            this.$refs.chat.scrollToBottom();
          }
        }
      });
    },
  },

  watch: {
    async activeChatId() {
      await this.updateActiveChat();
    },
  },
};
</script>

<style lang="scss">
.livehouse-app {
  .v-input__append-inner {
    margin-top: 4px;
  }
}
</style>
